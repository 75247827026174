import React from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
function Title({name}) {
  return (
    <HelmetProvider>

      <Helmet><title>{name}</title></Helmet>
    </HelmetProvider>
  )
}

export default Title