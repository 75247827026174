const inputStyle = {
    container: {
        marginTop: 5,
        marginBottom: 5
    },
    errorText: {
        color: '#DC143C'
    }
}

export default inputStyle