import apiClient from './client'

/**
 * This endpoint gets a school's details by it's subdomain
 */
const getSchool = (nickname) => {
    return apiClient.get(`/school/nickname/${nickname}`)
}

/**
 * This enpoint gets all schools in the system
 */
const getPublishedSchools = ()=> {
    return apiClient.get('/schools/published')
}

export {getSchool, getPublishedSchools}