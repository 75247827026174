
const prod = {
    url:{
        API_URL:'https://api.shsadmissions.com'
    }
}

const dev = {
    url:{
        // API_URL: 'http://ec2-3-142-93-225.us-east-2.compute.amazonaws.com'
        API_URL: 'https://api.shsadmissions.com'
    }
};

export const config = process.env.NODE_ENV === `development` ? dev : prod;