import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function AlertDialogAction(dialog = { open: false, title: '', children: '', disabled: false, handleClose:function(){},  onDialogSubmit: function () { } }) {
  const [openDialog, setOpenDialog] = useState(dialog.open);
  const [disabled, setDisabled] = useState(dialog.disabled)

  useEffect(() => {
    setOpenDialog(dialog.open)
    setDisabled(dialog.disabled)

  }, [dialog])

  return (
    <Dialog
      maxWidth="xs"
      open={openDialog}
      onClose={dialog.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {dialog.title}
      </DialogTitle>
      <DialogContent>
        {dialog.open && dialog.children}
      </DialogContent>
      <DialogActions>
        <Button disabled={disabled} onClick={dialog.handleClose} variant='contained' color='warning'>Cancel</Button>
        <Button disabled={disabled} onClick={dialog.onDialogSubmit} autoFocus variant='contained' color='primary'>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialogAction