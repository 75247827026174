import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getProspectus } from '../../api/admission'
import AlertDialog from '../../components/AlertDialog'
import colors from '../../config/colors'
import styles from '../../config/styles'
import { retrieveUser } from '../../repository/localstore'
import logger from '../../util/logger'
import AdmissionFormDashboard from './AdmissionFormDashboard'
import AdmissionFormPreview from './AdmissionFormPreview'

function AdmissionFormMain() {

    const navigate = useNavigate()
    const [openDialog, setOpenDialog] = useState({
        open: false,
        title: '',
        message: React.Component
    })
    const user = JSON.parse(retrieveUser('user'))
    const school = JSON.parse(retrieveUser('school'))
    const student = JSON.parse(retrieveUser('student'));

    const [confirmReg, setConfirmReg] = useState(false)
    const [data, setData] = useState({
        jhsIndexNumber: user.jhsIndexNumber,
        voucherNumber: user?.voucherNumber,
        schoolId: school?.school?.id,
        dateOfBirth: "",
        placeOfBirth: "",
        hometown: "",
        nationality: "Ghana",
        address: "",
        phoneNumber: "",
        religiousDenomination:"",
        region: "",
        physicalChallenge: "",
        enrolmentCode: "",
        nameOfLastSchoolAttended:"",
        addressOfLastSchoolAttended: "",
        nameOfFather: "",
        occupationOfFather: "",
        addressOfFather: "",
        phoneNumberOfFather: "",
        nameOfMother: "",
        occupationOfMother: "",
        addressOfMother: "",
        phoneNumberOfMother: "",
        nameOfGuardian: "",
        occupationOfGuardian: "",
        phoneNumberOfGuardian: "",
        postalAddressOfGuardian: "",
        residentialAddressOfGuardian: "",
        guardian: ""
    })

    const onSubmit = (values) => {

        setData((data) => ({
            ...data,
            ...values
        }))

        setConfirmReg(true)
        logger('onSubmitClick', values)

    }

    const onBackClick = () => {
        setConfirmReg(false)
    }

    const handleDialogClose = () => {
        setOpenDialog({ open: false, message: '', title: '' })
        navigate('/')
    }

    const getAdmissionData = async () => {
        const values = {
            schoolId: school?.school.id,
            jhsIndexNumber: user?.jhsIndexNumber,
            voucherNumber: user?.voucherNumber
        }
        const response = await getProspectus(values)
        logger('admission data', response.data)
        if (response.ok) {
            //if response failed
            setOpenDialog({ open: true, title: 'Notice', message: <div>You have already submitted your admission form</div>})
            return;
        }

    }

    useEffect(() => {
         getAdmissionData()

    }, [])


    return (
        <div style={{ ...styles.paddingTopHeader, backgroundColor: colors.lightgray }}>

            {!confirmReg && <AdmissionFormDashboard data={data} student={student} onSubmit={(values) => onSubmit(values)} />}
            {confirmReg && <AdmissionFormPreview data={data} student={student} onBackClick={() => onBackClick()} />}
            <AlertDialog open={openDialog.open} title={openDialog.title} children={openDialog.message} color="error" close='Close' handleClose={handleDialogClose} />

        </div>
    )
}

export default AdmissionFormMain