import React from 'react'
import { NavLink } from 'react-router-dom'
import colors from '../config/colors'

function Header({position='relative'}) {

    return (
            <nav className="navbar navbar-expand-sm navbar-light bg-light">
                <NavLink className="navbar-brand" to='/'>SHS Admissions</NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavId" aria-controls="collapsibleNavId"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="collapsibleNavId" >
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 " >

                        <li className="nav-item"><NavLink className='nav-link' to='/supported-schools'>Supported Schools</NavLink></li>
                        <li className="nav-item"><NavLink className='nav-link' to='/contact-us'>Contact Us</NavLink></li>
                        <li className="nav-item"><NavLink className='nav-link' to='/about-us'>About Us</NavLink></li>
                        <li className="nav-item"><NavLink className='nav-link' to='/privacy-policy'>Privacy Policy</NavLink></li>

                    </ul>

                </div>
            </nav>
    )
}

export default Header