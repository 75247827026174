import { Button } from "@mui/material";
import React from "react";
import imageITGirl from "../../assets/girl-home.png";
import routes from "../../config/routes";
import { useNavigate } from "react-router-dom";

function GenericPage({ handleGetStaredClick }) {
  const navigate = useNavigate();

  const handleClick = (route) => {
    navigate(route);
  };
  return (
    <>
      <div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 center-content" style={{ padding: 20 }}>
          <div>
            <img src={imageITGirl} width={200} height={200} alt="Girl on a laptop" />
          </div>
          <p></p>
          <h5 className="text-center">
            Get Admission in the comfort of your home. <br />
            Just report to school. <br />
            No long queue!!!
          </h5>

          <p></p>
          <div>
            <Button variant="contained" onClick={handleGetStaredClick}>
              Get Started
            </Button>
          </div>
        </div>

        <section id="section-menu">
          <div className="container d-flex justify-content-center align-items-center">
            <div className="row col-xs-12 col-sm-12 col-md-8 col-lg-8  m-2">
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-2">
                {/* <MenuCard title='Buy Voucher' route={routes.ROUTE_BUY_VOUCHER} icon={iconVoucher} /> */}
                <Button variant="contained" style={{ height: 80, width: "100%" }} onClick={() => handleClick(routes.ROUTE_BUY_VOUCHER)}>
                  Buy Voucher
                </Button>
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-2">
                <Button variant="contained" style={{ height: 80, width: "100%" }} onClick={() => handleClick(routes.ROUTE_RETRIEVE_VOUCHER)}>
                  Retrieve Voucher Code
                </Button>
              </div>

              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-2">
                <Button variant="contained" style={{ height: 80, width: "100%" }} onClick={() => handleClick(routes.ROUTE_ADMISSION)}>
                  Fill Admission Form
                </Button>

                {/* <MenuCard title='Fill Admission Form' route={routes.ROUTE_ADMISSION} icon={iconSchool} /> */}
              </div>

              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-2">
                {/* <MenuCard title='Check Admission Status/Download Prospectus' route={routes.ROUTE_ADMISSION_STATUS} icon={iconVerify} /> */}
                <Button variant="contained" style={{ height: 80, width: "100%" }} onClick={() => handleClick(routes.ROUTE_ADMISSION_STATUS)}>
                  Download Prospectus
                </Button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default GenericPage;
