import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {Carousel, CarouselItem } from 'react-bootstrap'

import { retrieveUser } from '../../repository/localstore'
import logger from '../../util/logger'


function SchoolCarousel() {
    const [images, setImages] = useState([])
    
    useEffect(()=>{
        const schoolData = JSON.parse(retrieveUser('school'))
        if (schoolData !== undefined || schoolData !== null){
            setImages(schoolData.images)

            logger('image', schoolData.images)
        }
        
    }, [])

    return (
        <>
        <Carousel indicators={false} fade controls={false}>
            {
                images.map((value, key) => {

                    return (
                        <Carousel.Item key={key} >
                                                    {/* className="d-block w-100" */}
                        <img 
                            src={value}
                            alt="Slides"
                            width="100%" 
                            className="img-responsive d-block w-100"
                            
                        />

                    </Carousel.Item>
                    )
                })
            }

        </Carousel>
     
        </>
    )

}

export default SchoolCarousel