import jsPDF from 'jspdf'
import React, { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import colors from '../../config/colors'
import { retrieveUser } from '../../repository/localstore'
import { useNavigate } from 'react-router-dom'
import logger from '../../util/logger'
import routes from '../../config/routes'
import html2canvas from 'html2canvas'

function AdmissionLetter(props) {
    const printCompRef = useRef()
    const navigate = useNavigate()

    const data = JSON.parse(retrieveUser('ddata'))

    useEffect(() => {
        logger('letter', data)
        // handlePrint();
        genPDF()
        // navigate("/" + routes.ROUTE_ADMISSION_STATUS + "/" + data.jhsIndexNumber, { replace: true })

    }, [])

    const handlePrint = useReactToPrint({
        content: () => printCompRef.current,
        pageStyle: `@page {
            size: 215mm 330mm;
            margin: 5mm
                }`


    })

    const genPDF = () => {
        const doc = new jsPDF();
        const elementHtml = document.getElementById('printToPDF');
        const specialElementHandlers = {
            "#elementH": function (element, renderer){
                return true;
            }
        };

        // doc.html(elementHtml, 15, 15, {
        //     'width': 170,
        //     'elementHandlers': specialElementHandlers
        // });
        doc.html(elementHtml, {
            x: 15, y: 15, width:170, elementHandlers:specialElementHandlers
        })
        doc.save('bio_data.pdf')
    }

    const printPDF = () => {
        const input = document.getElementById('printToPDF');

        html2canvas(input, { scale: 1.1 }).then(function (canvas) {
            // document.body.appendChild(canvas);
            var imgdata = canvas.toDataURL("image/jpg");
            var doc = new jsPDF({ orientation: 'portrait', unit: 'px', userUnit: 'px', format: 'a4' });
            doc.addImage(imgdata, "JPG", 5, 5);
            doc.save(`personal_record_${data.jhsIndexNumber}.pdf`)
            window.open(doc.output("bloburl"), "_blank");
        });

    }

    return (

        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center" style={{ paddingTop: 50, backgroundColor: colors.lightgray, minHeight: '92.5vh' }}>


            <div className="card col-xs-12 col-sm-12 col-md-6 col-lg-6" >
                <div className='card-body watermark' ref={printCompRef} id='printToPDF' style={{ fontFamily: 'serif' }}>

                    <div style={{ fontSize: 20 }}>
                        <div className='text-center'><img src={data?.houseAllocated?.school?.logoUrl} style={{ width: 80, height: 80 }} alt='school logo' /></div>
                        <div className='text-center' style={{ marginTop: 5 }}>
                            <div style={{ fontSize: 24, fontWeight: 'bold' }}>{data?.houseAllocated?.school?.name}</div>
                            <div>{data?.houseAllocated?.school?.address}</div>
                        </div>
                    </div>
                    {/* Date */}
                    <div style={{ position: '' }}>
                        { }
                    </div>



                    {/* Table for student's data */}
                    <div style={{ marginBottom: 10 }}>
                        {data?.name} <br />
                        {data?.address}
                    </div>

                    <p>Dear <strong>{data?.firstname}</strong>,</p>

                    <h5 className='text-center' style={{ marginTop: 20, textDecoration: 'underline', fontWeight:'bold' }}>Provisional Admission Letter</h5>

                    <div style={{ fontSize: 17 }}>
                        <ol style={{marginBottom:20}}>
                            <li><p>Congratulations on your admission to <strong>{data?.houseAllocated?.school?.name}</strong></p></li>
                            <li><p>Your admission number is <strong>{data?.admissionNumber}</strong>.</p></li>
                            <li><p>Your programme to be studied is <strong>{data?.programOffered}</strong></p></li>
                            <li><p>Your residential status is <strong>{data?.residentialStatus}</strong></p></li>
                            <li><p>You are affiliated to <strong>{data?.houseAllocated?.houseName}</strong></p></li>
                            <li><p>You have been admitted for the <strong>{data?.admissionYear}</strong> admission year</p></li>
                        </ol>

                    </div>



                    <div className='text-center' style={{ fontSize: 14, marginTop: 40, marginBottom: 2 }}>
                        ........................................... <br />
                        {data?.houseAllocated?.school?.name} <br />
                        {data?.houseAllocated?.school?.address} <br />
                        {data?.houseAllocated?.school?.location} <br />
                        {data?.houseAllocated?.school?.phoneNumber} <br />
                    </div>

                </div>

                <table className="table table-responsive" border="0" >
                                <tbody>
                                    <tr >
                                        <td colSpan={2} style={{ backgroundColor: colors.lightgray, borderRadius: 5 }} ><h5>Student Biodata</h5></td>
                                    </tr>
                                    <tr >
                                        <td>
                                            <div>Full Name</div>
                                            <strong>{data?.name}</strong>
                                        </td>
                                        <td>
                                            <div>Sex</div>
                                            <strong>{data?.gender}</strong>
                                        </td>

                                    </tr>

                                    <tr >
                                        <td>
                                            <div>Enrolment Code</div>
                                            <strong>{data?.enrolmentCode}</strong>
                                        </td>
                                        <td>
                                            <div>Date of Birth</div>
                                            <strong>{data?.dateOfBirth}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Place of Birth</div>
                                            <strong>{data?.placeOfBirth}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Address</div>
                                            <strong>{data?.address}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Hometown</div>
                                            <strong>{data?.hometown}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Phone Number</div>
                                            <strong>{data?.phoneNumber}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Nationality</div>
                                            <strong>{data?.nationality}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Physical Challenge</div>
                                            <strong>{data?.physicalChallenge}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Region</div>
                                            <strong>{data?.region}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Religious Denomination</div>
                                            <strong>{data?.religiousDenomination}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Name of Last School Attended</div>
                                            <strong>{data?.nameOfLastSchoolAttended}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Address of Last School</div>
                                            <strong>{data?.addressOfLastSchoolAttended}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ backgroundColor: colors.lightgray, borderRadius: 5 }} ><h5 >Parent Information</h5></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Name of Father</div>
                                            <strong>{data?.nameOfFather}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Name of Mother</div>
                                            <strong>{data?.nameOfMother}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Occupation of Father</div>
                                            <strong>{data?.occupationOfFather}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Occupation of Mother</div>
                                            <strong>{data?.occupationOfMother}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Address of Father</div>
                                            <strong>{data?.addressOfFather}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Address of Mother</div>
                                            <strong>{data?.addressOfMother}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Phone Number of Father</div>
                                            <strong>{data?.phoneNumberOfFather}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Phone Number of Mother</div>
                                            <strong>{data?.phoneNumberOfMother}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ backgroundColor: colors.lightgray, borderRadius: 5 }}><h5 >Guardian Information</h5></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Name of Guardian</div>
                                            <strong>{data?.nameOfGuardian}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Occupation of Guardian</div>
                                            <strong>{data?.occupationOfGuardian}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Phone Number of Guardian</div>
                                            <strong>{data?.phoneNumberOfGuardian}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Residential Address of Guardian</div>
                                            <strong>{data?.residentialAddressOfGuardian}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Postal Address Of Guardian</div>
                                            <strong>{data?.postalAddressOfGuardian}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Guardian</div>
                                            <strong>{data?.guardian}</strong>{" "}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={2} style={{ backgroundColor: colors.lightgray, borderRadius: 5 }}><h5 >Official Use Only</h5>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <div>Admission Number</div>
                                            <strong>{data?.admissionNumber}</strong>
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>
                                            <div>Track</div>
                                            <strong>{data?.track}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Program Offered</div>
                                            <strong>{data?.programOffered}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Residential Status</div>
                                            <strong>{data?.residentialStatus}</strong>
                                        </td>
                                        <td>
                                            <div>Class</div>
                                            <strong></strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>
                                            <div>House Allocated</div>
                                            <strong>{data?.houseAllocated?.houseName}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>House Parent</div>
                                            <strong>{data?.houseAllocated.houseMaster ? data?.houseAllocated?.houseMaster : data?.houseAllocated?.houseMistress}</strong>{" "}
                                        </td>
                                    </tr>

                                    <tr style={{ paddingBottom: 10 }}>
                                        <td>
                                            <div style={{ marginTop: 20 }}>
                                                Student's Signature:..................................................
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ marginTop: 20 }}>
                                                Date(DD/MM/YYYY):.....................................................
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

            </div>
        </div>

    )
}

export default AdmissionLetter