export default Object.freeze({
    height:{
        height: '92.5vh',
        minHeight: '92.5vh',
    },
    paddingTopHeader:{
        paddingTop: 50
    },
    formLogo: {
        height: 80,
        width: 80,
        paddingBottom:5
    },
    formButton:{
        width: '100%',
        height: 50 
    }
})