import React, { useEffect, useState } from 'react'
import correctIcon from '../../assets/correct.png';
import wrongIcon from '../../assets/wrong.png'
import loadingIcon from '../../assets/loading-state.gif'
import Title from '../../components/Title';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { retrieve, storeUser } from '../../repository/localstore';
import * as voucher from '../../api/voucher'
import routes from '../../config/routes';
import styles from '../../config/styles';
import logger from '../../util/logger';
import colors from '../../config/colors';
import { Button } from '@mui/material';
import AlertDialog from '../../components/AlertDialog';

function ConfirmVoucherPurchase() {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate();

    const [purchaseResponse, setPurchaseResponse] = useState(
        {
            title: 'Voucher Purchase Confirmation',
            message: 'Please wait while we confirm your voucher purchase',
            icon: loadingIcon
        }
    )
    const [openDialog, setOpenDialog] = useState({
        open: false,
        title: '',
        message: React.Component
    })

    const handleDialogClose = (event, reason) =>{
        if(reason !== 'backdropClick'){
            setOpenDialog({open: false, title: '', message: ''})

            navigate(`/${routes.ROUTE_ADMISSION_FORM}`, {replace: true})
        }

    }

    const confirmVoucherPurchase = async () => {
        const reference = searchParams.get('reference')
        const jhsIndexNumber = JSON.parse(retrieve('user')).jhsIndexNumber

        const response = await voucher.confirmVoucher({ jhsIndexNumber: jhsIndexNumber, reference: reference })

        if (!response.ok) {
            setPurchaseResponse({ title: 'Confirmation Failed', message: response.data.error, icon: wrongIcon })
            return;
        }



        logger(response)

        //if verification success
        //save data in session and redirect to admission form registration
        storeUser('user', JSON.stringify(response.data.data))

        const vvoucher = await voucher.verifyVoucher({ jhsIndexNumber: response.data.data.jhsIndexNumber, voucherNumber: response.data.data.voucherNumber });

        storeUser('student', JSON.stringify(vvoucher.data.student))
        storeUser('school', JSON.stringify(vvoucher.data))

        setPurchaseResponse({ title: 'Confirmation Success', message: 'Payment Successful,', icon: correctIcon })


        setTimeout(() => {

            // navigate(`/${routes.ROUTE_ADMISSION_FORM}`)
            setOpenDialog({open: true, title: 'Keep Voucher Safe', message: <div>Your Voucher Number is: <strong>{response.data.data.voucherNumber}</strong></div>})
        }, 1500);
    }

    useEffect(() => {
        confirmVoucherPurchase()

    }, [])

    return (
        <>
            {/* <Header /> */}
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center" style={{ backgroundColor: colors.lightgray, ...styles.height }}>
                <Title name='Voucher Confirmation' />

                <div className='col-xs-12 col-sm-12 col-md-5 col-lg-4 '>

                    <div className="card align-items-center">
                        <img src={purchaseResponse.icon} width={50} height={50} alt="Verification State Icon" style={{ marginTop: 10, marginBottom: 5 }} />
                        <div className="card-body  text-center">
                            <h4 className="card-title">{purchaseResponse.title}</h4>
                            <div className="card-text">{purchaseResponse.message}</div>
                            <Button variant='contained' onClick={() => { navigate('/') }} hidden={purchaseResponse.icon !== wrongIcon}>Back</Button>
                        </div>
                    </div>
                </div>

                <AlertDialog open={openDialog.open} title={openDialog.title} children={openDialog.message} handleClose={handleDialogClose} color='primary' close="Continue" />

            </div>
        </>
    )
}

export default ConfirmVoucherPurchase