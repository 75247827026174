import React, { useEffect, useState } from 'react'
import '../routes/HomePageRouter'
import { useNavigate } from 'react-router-dom'
import routes from '../config/routes'
import Title from '../components/Title'
import SchoolPage from './main/SchoolPage'
import GenericPage from './main/GenericPage'
import { clearSession, retrieveUser } from '../repository/localstore'
import logger from '../util/logger'

function WelcomeScreen() {
    const navigate = useNavigate();
    const [isSchool, setSchool] = useState(false)

    const handleGetStaredClick = () => {
        navigate(routes.ROUTE_BUY_VOUCHER)
    }

    const swapComponentOnSchool = () => {
        const school = JSON.parse(retrieveUser('school'))
        let isSubdomain = JSON.parse(retrieveUser("isSubdomain"));
        
        logger(school)
        if (!isSubdomain) {
            setSchool(false)
            logger("isSubdomain", isSubdomain+" yea")
        }
        else {
            setSchool(true)
        }
    }

    useEffect(() => {
        swapComponentOnSchool()

    }, [])


    return (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <Title name='SHS Admissions' />
                {/* <GenericPage handleGetStaredClick={handleGetStaredClick} /> */}
                <div>

                    {
                        isSchool ? <SchoolPage handleGetStaredClick={handleGetStaredClick} /> :
                            <GenericPage handleGetStaredClick={handleGetStaredClick} />
                    }

                </div>



            </div>

    )
}

export default WelcomeScreen