import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import routes from "../../config/routes";
import iconSchool from "../../assets/school.png";
import { retrieveUser } from "../../repository/localstore";
import { useNavigate } from "react-router-dom";
import styles from "../../config/styles";
import logger from "../../util/logger";

function SchoolPage({ handleGetStaredClick }) {
  const [school, setSchool] = useState({});
  const [bgImage, setBbImage] = useState("");
  const navigate = useNavigate();

  const handleClick = (route) => {
    navigate(route);
  };

  useEffect(() => {
    const sch = JSON.parse(retrieveUser("school"));
    setSchool(sch);
    setBbImage(sch?.images?.length > 0 ? sch?.images[0] : "");
    logger("bg", sch);
  }, []);

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-12 col-lg-12  d-flex flex-column justify-content-center align-items-center"
      style={{
        ...styles.height,
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(${bgImage})`,
        backgroundSize: "cover",
      }}
    >
      <div className="container col-xs-10 col-sm-10 col-md-8 col-lg-8">
        <div className="card " style={{ backgroundColor: "rgba(255,255,255,1)" }}>
          <div className="card-body">
            <div className="text-center">
              <img
                className="mt-2"
                src={school?.school?.logoUrl ? school.school.logoUrl : iconSchool}
                style={{ width: 100, height: 100 }}
                alt="school logo"
              />
              <h3 className="text-uppercase">{school?.school?.name}</h3>
              <div className="text-center">
                Get Admission in the comfort of your home. <br />
                Just report to school. <br />
                No long queue!!!
              </div>

              <p></p>

              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-2">
                    <Button variant="contained" style={{ height: 80, width: "100%" }} onClick={() => handleClick(routes.ROUTE_BUY_VOUCHER)}>
                      Buy Voucher
                    </Button>
                  </div>
                  <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-2">
                    <Button variant="contained" style={{ height: 80, width: "100%" }} onClick={() => handleClick(routes.ROUTE_RETRIEVE_VOUCHER)}>
                      Retrieve Voucher Code
                    </Button>
                  </div>

                  <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-2">
                    <Button variant="contained" style={{ height: 80, width: "100%" }} onClick={() => handleClick(routes.ROUTE_ADMISSION)}>
                      Fill Admission Form
                    </Button>
                  </div>

                  <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-2">
                    <Button variant="contained" style={{ height: 80, width: "100%" }} onClick={() => handleClick(routes.ROUTE_ADMISSION_STATUS)}>
                      Download Prospectus
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchoolPage;
