import React, { useEffect, useState } from 'react'

import Title from '../../components/Title'
import styles from '../../config/styles'
import colors from '../../config/colors'
import Call from '@mui/icons-material/Call'

function ContactUs() {



  return (
    <div>
      <Title name='Contact Us' />


      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ ...styles.paddingTopHeader, ...styles.height, backgroundColor: colors.lightgray }}>

        <div className='container' style={{ padding: 20, height: '100%' }}>
          {/* <h1 className="text-center">Contact Us</h1> */}
          <div className='text-center d-flex flex-column justify-content-center align-items-center' style={{ padding: 10 }}>
            <h4>SHS Admissions</h4>
            <span>Want to get in touch and work with us? Here's how you can reach us</span>

            <div className="card card-body col-xs-12 col-sm-12 col-md-4 col-lg-4 " style={{ backgroundColor: colors.white, marginTop: 50 }}>
              <div className='text-center'>
                <Call style={{ width: 50, height: 50 }} />
              </div>
              <h5>Call Us</h5>
              <div >
                Phone: <a href='tel:0249640111' style={{ textDecoration: 'none' }} >0249640111</a> / <a href='tel:0546869171' style={{ textDecoration: 'none' }}>0546869171</a>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  )
}

export default ContactUs