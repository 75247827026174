import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Title from '../../components/Title'
import { retrieveUser, storeUser } from '../../repository/localstore'
import * as voucher from '../../api/voucher'
import { Button } from '@mui/material'
import styles from '../../config/styles'
import colors from '../../config/colors'
import logger from '../../util/logger'
import { getAdmissionLetter, getProspectus } from '../../api/admission';
import AlertDialog from '../../components/AlertDialog'
import routes from '../../config/routes'
import StudentData from '../../components/download/StudentData'
import PrintDialog from '../../components/dialogs/PrintDialog'
import AdmissionLetter from '../../components/download/AdmissionLetter'
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import jsPDF from 'jspdf'
import { create } from "apisauce";
import { config } from '../../util/Constants'
import CloudDownload from '@mui/icons-material/CloudDownload'
import PrintRounded from '@mui/icons-material/PrintRounded'




function AdmissionStatusDashboard() {
  const user = JSON.parse(retrieveUser('user'));
  const school = JSON.parse(retrieveUser('school'))

  const loadFile = async (url, callback) => {
    PizZipUtils.getBinaryContent(url, await callback);

  }

  const navigate = useNavigate()
  const [openDialog, setOpenDialog] = useState({
    open: false,
    message: React.Component,
    title: ''
  });
  const [downloadData, setDownloadData] = useState([

  ])

  const [openPrintDialog, setOpenPrintDialog] = useState({
    open: false,
    title: '',
    children: React.Component
  })


  const handleDialogClose = () => {
    setOpenDialog({ open: false, message: '', title: '' })
    navigate('/')
  }

  //this method verifies user's voucher code and indexNumber when this page is opened
  const verifyUser = async () => {
    logger(user)
    if (user === null) {
      navigate('/');
      return
    }

    const response = await voucher.verifyVoucher({ jhsIndexNumber: user.jhsIndexNumber, voucherNumber: user.voucherNumber })
    if (!response.ok) {
      navigate('/')
      return;
    }
  }

  //get data
  const getAdmissionData = async () => {

    const values = {
      schoolId: school?.school.id,
      jhsIndexNumber: user.jhsIndexNumber,
      voucherNumber: user.voucherNumber
    }
    const response = await getProspectus(values)
    logger('admission data', response)
    if (!response.ok) {
      //if response failed
      if (response.status !== 400)
        setOpenDialog({ open: true, title: 'Notice', message: <div> {response.data?.message }</div>})
      return;
    }

    //response is ok
    //set download data
    setDownloadData(response.data.documents)
    storeUser('ddata', JSON.stringify(response.data))
  }

  //when the print button is clicked
  const handlePrintClick = (route, data = {}) => {
    if (route === 'biodata') {
      // setOpenPrintDialog({open:true, title:'Bio Data', children:StudentData })
      navigate('/' + routes.ROUTE_ADMISSION_BIODATA)
    }
    else if( route === "admission_letter"){
      downloadAdmissionLetter()

    }
    else if (route === "files") {
        window.open(data.url)
    }
  }

  const handlePrintDialogClose = () => {
    setOpenPrintDialog({ open: false, title: '', children: null })
  }


  const generateDocument = async (url) => {
    const data = JSON.parse(retrieveUser('ddata'))

    const ap = create({});

    const da = await ap.get(url, '', {
      responseType: '',
      // headers:{
      //   'Content-Type': 'application/gzip'
      // }
      headers: {
        'Content-Type': 'application/json'
      }

    })

    logger('da', da)


    await loadFile(
      url,
      function (error, content) {
        if (error) {
          throw error;
        }
        logger("responsetype", url)
        logger("document", content)
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });

        //render the document (replace all occurances)
        doc.render({
          name: data.name,
          programOffered: data?.programOffered,
          residentialStatus: data?.residentialStatus,
          houseAllocated: data?.houseAllocated?.houseName,
        });

        const out = doc.getZip().generate({
          type: "blob",
          mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });//output using data uri        

        saveAs(out, "Admission_Letter.docx");
      }
    );
  };

  const downloadAdmissionLetter = async () => {
    const values = {
      jhsIndexNumber: user.jhsIndexNumber,
      voucherNumber: user.voucherNumber,
      schoolId: school?.school.id
    }
    const ff = `${config.url.API_URL}/student/admission-letter/${values.schoolId}/${values.jhsIndexNumber}/${values.voucherNumber}`
    window.open(ff);
  }


  useEffect(() => {
    verifyUser()
    getAdmissionData()
  }, [])


  return (
    <>
      <div className='d-flex justify-content-center' style={{ backgroundColor: colors.lightgray, ...styles.height }}>
        <Title name='Admission Status Dashboard' />

        <div className='text-center col-xs-12 col-sm-12 col-md-6 col-lg-6' style={{ ...styles.paddingTopHeader, }}>

          <div className='card'>
            <div className='card-body'>
              <div className='alert alert-primary'>
                Download, Print and complete the following documents.   Carefully follow the instructions on each document and present them on arrival to campus.
              </div>
              <table className="table table-striped table-inverse table-responsive">
                <thead className="thead-inverse">
                  <tr>
                    <th>No</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Biodata</td>
                    <td><Button variant='contained' size='small' onClick={() => handlePrintClick('biodata')}>Print</Button></td>
                  </tr>
                  <tr>

                    <td>2</td>
                    <td>Admission Letter</td>
                    <td><Button variant='contained' size='small' onClick={() => handlePrintClick('admission_letter')}>Download</Button></td>
                  </tr>

                  {downloadData.map((row, key) => (
                    <tr key={key}>
                      <td>{key + 3}</td>
                      <td>{row.description}</td>
                      <td>
                        <Button variant='contained' size='small' onClick={() => handlePrintClick('files', row)} id="btn" tooltip="Download">Download</Button>
                        
                      </td>
                    </tr>
                  ))
                  }

                </tbody>
              </table>
            </div>

          </div>

        </div>

        <AlertDialog open={openDialog.open} title={openDialog.title} color="error" close='Close' children={openDialog.message} handleClose={handleDialogClose} />

        {/*  */}
        {/* <PrintDialog open={openPrintDialog.open} title={openPrintDialog.title} handleClose={handlePrintDialogClose} children={openPrintDialog.children} /> */}
      </div>
    </>
  )
}

export default AdmissionStatusDashboard