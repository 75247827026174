import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup'
import iconVerify from '../../assets/verify.png'
import AppSnackBar from '../../components/AppSnackBar';
import Title from '../../components/Title';
import { Box, Button } from '@mui/material';
import * as voucher from '../../api/voucher'
import severity from '../../config/severity';
import { retrieveUser, storeUser } from '../../repository/localstore';
import styles from '../../config/styles';
import InputTextField from '../../components/mat/InputTextField';
import SchoolPicture from '../../components/school/SchoolPicture';
import colors from '../../config/colors';
import { getProspectus } from '../../api/admission';
import AlertDialog from '../../components/AlertDialog';

const validationSchema = Yup.object().shape({
  voucherNumber: Yup.string().required().label('Voucher Number'),
  jhsIndexNumber: Yup.string().required().min(12).max(12).label('JHS Index Number')
})

function AdmissionStatus() {
  //for navigating programmatically
  let navigate = useNavigate()
  const [schoolLogo, setSchoolLogo] = useState("");
  const [buttonState, setButtonState] = useState({ disabled: false, text: 'Check Status' })
  const isSubdomain = JSON.parse(retrieveUser("isSubdomain"));
  //set the default props for the snackbar
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: 'success',
    message: ''
  })
  const [openDialog, setOpenDialog] = useState({
    open: false,
    title: '',
    message: React.Component
  })

  const [admissionStatus, setAdmissionStatus] = useState({
    jhsIndexNumber: '',
    voucherNumber: ''
  });

  const onSubmit = async (values) => {
    setButtonState({ disabled: true, text: 'Please wait...' })
    const response = await voucher.verifyVoucher(values);

    if (!response.ok) {
      setButtonState({ disabled: false, text: 'Check Status' })
      setSnackBarProps({ open: true, severity: severity.error, message: response.data.message })
      return;
    }

    //if verification success,
    //check if user has already filled the form
    const sch = response.data;
    const checkForm = await getProspectus({ ...values, schoolId: sch.school.id });
    if (!checkForm.ok) {
      //user has not filled form
      if (response.status !== 400)
        setOpenDialog({ open: true, title: 'Notice', message: <div>{checkForm.data.message }</div>})
      setButtonState({ disabled: false, text: 'Check Status' })
      return
    }
    //save data to session storage
    storeUser('user', JSON.stringify({ jhsIndexNumber: values.jhsIndexNumber, voucherNumber: values.voucherNumber }))
    if (JSON.parse(retrieveUser('school')) === null) {
      storeUser('school', JSON.stringify(sch))
     }
    navigate(values.jhsIndexNumber)
  }


  const handleSnackClose = () => {
    setSnackBarProps({ open: false, severity: 'error', message: '' })
  }
  const handleDialogClose = () => {
    setOpenDialog({ open: false, message: '', title: '' })
  }

  useEffect(() => {
    const school = JSON.parse(retrieveUser('school'))
    if (school != null)
      setSchoolLogo(school.school.logoUrl)

  }, [schoolLogo])


  return (
    <>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center" style={{ ...styles.height }}>
        <Title name='Admission Status' />


        <Formik initialValues={admissionStatus} enableReinitialize={true} validationSchema={validationSchema} onSubmit={onSubmit}>

          {
            ({ handleSubmit, handleChange, setFieldValue }) => (
              <>


                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-none d-sm-block" style={{ height: '100%', backgroundColor: colors.lightgray }}>
                  <div className='d-flex justify-content-center align-items-center' style={{ height: '100%' }}>

                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 '>

                      <SchoolPicture />

                    </div>
                  </div>
                </div>

                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>

                  <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6' style={{ margin: 'auto' }} >
                    <div className='container'>

                      <div style={{ paddingBottom: 20 }} >
                        <div className="text-center">
                          <img src={(schoolLogo && isSubdomain) ? schoolLogo : iconVerify} style={styles.formLogo} alt='school logo' />
                        </div>
                        <h4 className='text-center'>Download Prospectus</h4>
                        <div className='alert alert-primary'>
                          <ul>
                            <li>Enter your Voucher Number</li>
                            <li>Enter your 10 digits Index Number followed by the <strong>year. eg. 100000023</strong></li>
                          </ul>
                        </div>
                      </div>
                      <Box
                        component='form'
                        sx={{
                          '& .MuiTextField-root': { marginBottom: 2, width: '100%', alignItems: 'stretch' },
                        }}

                        noValidate
                        autoComplete="on"
                        autoSave='on'>

                        <InputTextField label='JHS Index Number' name='jhsIndexNumber' size='large' inputProps={{ maxLength: 12 }}/>

                        <InputTextField label='Voucher Number' name='voucherNumber' placeholder="Enter Voucher Number Received via SMS" size='large' />

                        <div style={{ marginTop: 10 }}>

                          <Button type='button' style={styles.formButton} disabled={buttonState.disabled} variant="contained" onClick={handleSubmit} >{buttonState.text}</Button>
                        </div>
                      </Box>
                    </div>

                    <AppSnackBar open={snackBarProps.open} severity={snackBarProps.severity} message={snackBarProps.message} />
                  </div>
                </div>


              </>
            )
          }

        </Formik>

        <AppSnackBar open={snackBarProps.open} severity={snackBarProps.severity} message={snackBarProps.message} handleClose={handleSnackClose} />
        <AlertDialog open={openDialog.open} title={openDialog.title} children={openDialog.message} color="error" close='Close' handleClose={handleDialogClose} />

      </div>
    </>

  )
}

export default AdmissionStatus