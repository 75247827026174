import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import iconBrokenLink from '../assets/broken-link.png'
import Header from '../components/Header'
import colors from '../config/colors'
import styles from '../config/styles'

function NotFound({message=""}) {
  const navigate = useNavigate()
  return (
    <>
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center" style={{ ...styles.height, backgroundColor:colors.lightgray}}>
        
        <div className="card col-xs-12 col-sm-12 col-md-8 col-lg-4" >
            <div className="card-body text-center">
            <img src={iconBrokenLink} height={50} width={50} alt="failed route"/>
                <h4 className="card-title">Oops..</h4>
                {message === "" ? <p className="card-text">The page you requested for is not found on our server</p> :
                  <p className='card-text'>{message} Check your url or click <strong>Home</strong></p>
                }
                <div>
                  <Button variant='contained' onClick={()=>{window.location = "https://shsadmissions.com"}}>Home</Button>
                </div>
            </div>
        </div>

    </div>

    {/* <tbody>
                                    <tr>
                                        <td colSpan={2} style={{ backgroundColor:  colors.lightgray, borderRadius: 5 }} ><h5>Student Biodata</h5></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Full Name: {" "} 
                                            <strong>{data.name}</strong>
                                        </td>
                                        <td>
                                            Sex: {" "} 
                                            <strong>{data.gender}</strong>
                                        </td>

                                    </tr>
                                   
                                    <tr>
                                        <td>
                                            Enrolment Code: {" "} 
                                            <strong>{data.enrolmentCode}</strong>
                                        </td>
                                        <td>
                                            Date of Birth: {" "} 
                                            <strong>{data.dateOfBirth}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Place of Birth: {" "} 
                                            <strong>{data.placeOfBirth}</strong>{" "}
                                        </td>
                                        <td>
                                            Address: {" "} 
                                            <strong>{data.address}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Hometown: {" "} 
                                            <strong>{data.hometown}</strong>{" "}
                                        </td>
                                        <td>
                                            Phone Number: {" "} 
                                            <strong>{data.phoneNumber}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Nationality: {" "} 
                                            <strong>{data.nationality}</strong>{" "}
                                        </td>
                                        <td>
                                            Physical Challenge: {" "} 
                                            <strong>{data.physicalChallenge}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Region: {" "} 
                                            <strong>{data.region}</strong>{" "}
                                        </td>
                                        <td>
                                            Religious Denomination: {" "} 
                                            <strong>{data.religiousDenomination}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Name of Last School Attended: {" "} 
                                            <strong>{data.nameOfLastSchoolAttended}</strong>{" "}
                                        </td>
                                        <td>
                                            Address of Last School: {" "} 
                                            <strong>{data.addressOfLastSchoolAttended}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ backgroundColor:  colors.lightgray, borderRadius:5 }} ><h5 >Parent Information</h5></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Name of Father: {" "} 
                                            <strong>{data.nameOfFather}</strong>{" "}
                                        </td>
                                        <td>
                                            Name of Mother: {" "} 
                                            <strong>{data.nameOfMother}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Occupation of Father: {" "} 
                                            <strong>{data.occupationOfFather}</strong>{" "}
                                        </td>
                                        <td>
                                            Occupation of Mother: {" "} 
                                            <strong>{data.occupationOfMother}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Address of Father: {" "} 
                                            <strong>{data.addressOfFather}</strong>{" "}
                                        </td>
                                        <td>
                                            Address of Mother: {" "} 
                                            <strong>{data.addressOfMother}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Phone Number of Father: {" "} 
                                            <strong>{data.phoneNumberOfFather}</strong>{" "}
                                        </td>
                                        <td>
                                            Phone Number of Mother: {" "} 
                                            <strong>{data.phoneNumberOfMother}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ backgroundColor: colors.lightgray, borderRadius:  5 }}><h5 >Guardian Information</h5></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Name of Guardian: {" "} 
                                            <strong>{data.nameOfGuardian}</strong>{" "}
                                        </td>
                                        <td>
                                            Occupation of Guardian: {" "} 
                                            <strong>{data.occupationOfGuardian}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Phone Number of Guardian: {" "} 
                                            <strong>{data.phoneNumberOfGuardian}</strong>{" "}
                                        </td>
                                        <td>
                                            Residential Address of Guardian: {" "} 
                                            <strong>{data.residentialAddressOfGuardian}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Postal Address Of Guardian: {" "} 
                                            <strong>{data.postalAddressOfGuardian}</strong>{" "}
                                        </td>
                                        <td>
                                            Guardian: {" "} 
                                            <strong>{data?.guardian}</strong>{" "}
                                        </td>
                                    </tr>
                                  
                                    <tr>
                                        <td  colSpan={2} style={{ backgroundColor: colors.lightgray, borderRadius:  5 }}><h5 >Official Use</h5>
                                       
                                        </td>
                                    </tr>
                                    <tr>
                                    <td colSpan={2}>
                                            Admission Number: {" "} 
                                            <strong>{data.admissionNumber}</strong>
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>
                                            Track: {" "} 
                                            <strong>{data.track}GOLD</strong>{" "}
                                        </td>
                                        <td>
                                            Program Offered: {" "} 
                                            <strong>{data.programOffered}Home Economics</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>
                                            Residential Status: {" "} 
                                            <strong>{data.residentialStatus}Boarding</strong>
                                        </td>
                                        <td>
                                            Class: {" "} 
                                            <strong>{data.track} BUS A1</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                       
                                        <td>
                                            House Allocated: {" "} 
                                            <strong>{data.track}Kwame Nkrumah House</strong>{" "}
                                        </td>
                                        <td>
                                            House Parent: {" "} 
                                            <strong>{data.gender === "Male" ? data.houseAllocated.houseMaster: data.houseAllocated.houseMistress} Mr. Markus Amaglo</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={5}>Student's Signature: {" "}...........................</td>
                                        <td rowSpan={5}>Date(DD/MM/YYYY): {" "}..............................</td>
                                    </tr>
                                </tbody> */}
    </>
  )
}

export default NotFound