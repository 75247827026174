import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import routes from '../../config/routes'
import iconSchool from '../../assets/school.png'
import AppSnackBar from '../../components/AppSnackBar'
import severity from '../../config/severity'
import Title from '../../components/Title'
import * as voucher from '../../api/voucher'
import { retrieveUser, storeUser } from '../../repository/localstore'
import { Box, Button } from '@mui/material'
import styles from '../../config/styles'
import InputTextField from '../../components/mat/InputTextField'
import colors from '../../config/colors'
import SchoolPicture from '../../components/school/SchoolPicture'
import logger from '../../util/logger'
import getAdmissionData from '../../api/hook/admissionstatus'
import { getProspectus } from '../../api/admission'
import AlertDialog from '../../components/AlertDialog'
import SchoolCarousel from '../../components/school/SchoolCarousel'

const validationSchema = Yup.object().shape({
  voucherNumber: Yup.string().required().label('Voucher Number'),
  jhsIndexNumber: Yup.string().required().min(12).max(12).label('JHS Index Number')
})


function AdmissionForm() {
  //navigate programmatically
  const navigate = useNavigate()
  const [buttonState, setButtonState] = useState({ disabled: false, text: 'Fill Form' })
  const [schoolLogo, setSchoolLogo] = useState("");
  const isSubdomain = JSON.parse(retrieveUser("isSubdomain"));
  //set the default props for the snackbar
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: 'success',
    message: ''
  })
  const [openDialog, setOpenDialog] = useState({
    open: false,
    title: '',
    message: ''
  })
  const [admission, setAdmission] = useState({
    indexNumber: '',
    voucherCode: ''
  });

  const onSubmit = async (values) => {
    setButtonState({ disabled: true, text: 'Please wait...' })
    // setConsole(JSON.stringify(values))
    const response = await voucher.verifyVoucher(values);
    logger("verify voucher", response.data)
    if (!response.ok) {
      setButtonState({ disabled: false, text: 'Fill form' })
      setSnackBarProps({ open: true, severity: severity.error, message: response?.data?.message || response?.response?.data?.message })
      return;
    }

    //if verification success,

    //check if user has already filled the form
    const sch = response.data;
    const checkForm = await getProspectus({ ...values, schoolId: sch.school.id });
    if (checkForm.ok) {
      //user has already filled form
      setOpenDialog({ open: true, title: 'Notice', message: "You have already submitted your admission form" })
      setButtonState({ disabled: false, text: 'Fill form' })
      return
    }

    //save data to session storage
    storeUser('user', JSON.stringify({ jhsIndexNumber: values.jhsIndexNumber, voucherNumber: values.voucherNumber }))
    storeUser('student', JSON.stringify(response.data.student))
    if (JSON.parse(retrieveUser('school')) === null) {
      storeUser('school', JSON.stringify(sch))
    }
    navigate("/" + routes.ROUTE_ADMISSION_FORM)

  }

  useEffect(() => {
    const school = JSON.parse(retrieveUser('school'))
    if (school){
      setSchoolLogo(school?.school?.logoUrl)
    }

  }, [schoolLogo])

  const handleSnackClose = () => {
    setSnackBarProps({ open: false, severity: 'error', message: '' })
  }
  const handleDialogClose = () => {
    setOpenDialog({ open: false, message: '', title: '' })
  }

  return (
    <>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center" style={{ ...styles.height }}>

        <Title name='Admission' />

        <Formik initialValues={admission} enableReinitialize={true} validationSchema={validationSchema} onSubmit={onSubmit}>

          {
            ({ handleSubmit, handleChange, setFieldValue }) => (
              <>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-none d-sm-block" style={{ height: '100%', backgroundColor: colors.lightgray }}>
                  <div className='d-flex justify-content-center align-items-center' style={{ height: '100%' }}>

                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 '>

                      <SchoolPicture />

                    </div>
                  </div>
                </div>

                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6' >

                  <div className='col-xs-11 col-sm-11 col-md-6 col-lg-6' style={{ margin: 'auto' }} >
                    <div className='container'>

                      <div style={{ paddingBottom: 20 }} >
                        <div className="text-center">
                          <img src={(schoolLogo && isSubdomain) ? schoolLogo : iconSchool} style={styles.formLogo} alt='school logo' />
                        </div>
                        <h4 className='text-center'>Fill Admission Form</h4>
                        <div className='alert alert-primary'>
                          <ul>
                            <li>Enter your Voucher Number</li>
                            <li>Enter your 10 digits Index Number followed by the <strong>year. eg. 100000023</strong></li>
                          </ul>
                        </div>
                      </div>
                      <Box
                        component='form'
                        sx={{
                          '& .MuiTextField-root': { marginBottom: 2, width: '100%', alignItems: 'stretch' },
                        }}

                        noValidate
                        autoComplete="on"
                        autoSave='on'>

                        <InputTextField label='JHS Index Number' name='jhsIndexNumber' size='large' inputProps={{ maxLength: 12 }}/>

                        <InputTextField label='Voucher Number' name='voucherNumber' placeholder="Enter Voucher Number Received via SMS" size='large' />

                        <div style={{ marginTop: 10 }}>

                          <Button type='button' style={styles.formButton} disabled={buttonState.disabled} variant="contained" onClick={handleSubmit} >{buttonState.text}</Button>
                        </div>
                      </Box>
                    </div>
                  </div>

                  <AppSnackBar open={snackBarProps.open} severity={snackBarProps.severity} message={snackBarProps.message} handleClose={handleSnackClose} />
                </div>
                <AlertDialog open={openDialog.open} title={openDialog.title} children={openDialog.message} color="error" close='Close' handleClose={handleDialogClose} />

              </>
            )
          }

        </Formik>


      </div>
    </>

  )
}

export default AdmissionForm