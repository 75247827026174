import { Button } from "@mui/material";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { submitStudentForm } from "../../api/admission";
import AlertDialogAction from "../../components/AlertDialogAction";
import Title from "../../components/Title";
import colors from "../../config/colors";
import routes from "../../config/routes";
import { retrieveUser, storeUser } from "../../repository/localstore";
import logger from "../../util/logger";

const dialogTitle = 'Submit Data';
const dialogMessage = <div>You will <strong>NOT</strong> be able to change your info after submitting.</div>

function AdmissionFormPreview(props) {
  const navigate = useNavigate()
  const [user, setUser] = useState({})
  const { data, student } = props;
  //set the default props for the snackbar
  const [dialogProps, setDialogProps] = useState({
    open: false,
    title: '',
    message: React.Component,
    disabled: false
  })

  const onConfirmClicked = () => {
    setDialogProps({ open: true, title: dialogTitle, message: dialogMessage, disabled: false })
  }

  //callback from dialog close
  const handleDialogClose = () => {
    setDialogProps({ open: false, title: dialogTitle, message: "", disabled: false })
  }

  //callback from the dialog data
  const handleOnDialogSubmit = () => {

    submitData()
    
  }
  
  //submit the data to the backend
  const submitData = async()=> {
    
    setDialogProps({ open: true, title: 'Confirm Data', message: <div>Submiting data... please wait</div>, disabled: true })
    const response = await submitStudentForm(data);
    logger('response', response.data)
    if(!response.ok){
      let validationError = Object.values(response?.data?.error || {})[0];
      let errorMessage = response?.response?.data?.message || response?.data?.message || validationError || "Something went wrong, try again"
      
      setDialogProps({ open: true, title: 'Failed', message: <div>{errorMessage}</div>, disabled: false })
      return;
    }

    setDialogProps({ open: true, title: 'Confirm Data', message: <div>Data Submitted Successfully</div>, disabled: true })
    
    //store response
    storeUser('student_data', JSON.stringify(response.data))

    setTimeout(() => {
      navigate(`/${routes.ROUTE_ADMISSION_STATUS}/${user.jhsIndexNumber}`)
    }, 1500);

  }

  const printPDF = () => {
    const input = document.getElementById('printToPDF');
    const pdf = new jsPDF({ unit: 'px', format: 'letter', userUnit: 'px' });

    pdf.html(input, { html2canvas: { scale: 0.85 } }).then(() => {
      pdf.save(`personal_record_${user.jhsIndexNumber}.pdf`)
      window.open(pdf.output("bloburl"), "_blank");
    })

  }

  useEffect(() => {
    setUser(JSON.parse(retrieveUser('user')))
  }, [])


  logger("params", data);
  return (
    <div>
      <Title name='Admission Confirmation' />

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center" >

        <div className="card col-xs-12 col-sm-12 col-md-8 col-lg-6">

          <div className="card-body">
            <div id="printToPDF">
              <table className="table table table-responsive">
                <tbody>
                  <tr>
                    <td colSpan={2} style={{backgroundColor: colors.lightgray, borderRadius:5}} ><h5>Student Biodata</h5></td>
                  </tr>
                  <tr>
                    <td>
                      <div>Name</div>
                      <strong>{student.name}</strong>
                    </td>
                    <td>
                      <div>Program Offered</div>
                      <strong>{student.programOffered}</strong>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>JHS Index Number</div>
                      <strong>{data.jhsIndexNumber}</strong>
                    </td>
                    <td>
                      <div>Track</div>
                      <strong>{student.track}</strong>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>Enrolment Code</div>
                      <strong>{data.enrolmentCode}</strong>
                    </td>
                    <td>
                      <div>Date of Birth</div>
                      <strong>{data.dateOfBirth}</strong>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>Place of Birth</div>
                      <strong>{data.placeOfBirth}</strong>{" "}
                    </td>
                    <td>
                      <div>Address</div>
                      <strong>{data.address}</strong>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>Hometown</div>
                      <strong>{data.hometown}</strong>{" "}
                    </td>
                    <td>
                      <div>Phone Number</div>
                      <strong>{data.phoneNumber}</strong>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>Nationality</div>
                      <strong>{data.nationality}</strong>{" "}
                    </td>
                    <td>
                      <div>Physical Challenge</div>
                      <strong>{data.physicalChallenge}</strong>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>Region</div>
                      <strong>{data.region}</strong>{" "}
                    </td>
                    <td>
                      <div>Religious Denomination</div>
                      <strong>{data.religiousDenomination}</strong>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>Name of Last School Attended</div>
                      <strong>{data.nameOfLastSchoolAttended}</strong>{" "}
                    </td>
                    <td>
                      <div>Address of Last School</div>
                      <strong>{data.addressOfLastSchoolAttended}</strong>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{backgroundColor: colors.lightgray, borderRadius:5}} ><h5 >Parent Information</h5></td>
                  </tr>
                  <tr>
                    <td>
                      <div>Name of Father</div>
                      <strong>{data.nameOfFather}</strong>{" "}
                    </td>
                    <td>
                      <div>Name of Mother</div>
                      <strong>{data.nameOfMother}</strong>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>Occupation of Father</div>
                      <strong>{data.occupationOfFather}</strong>{" "}
                    </td>
                    <td>
                      <div>Occupation of Mother</div>
                      <strong>{data.occupationOfMother}</strong>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>Address of Father</div>
                      <strong>{data.addressOfFather}</strong>{" "}
                    </td>
                    <td>
                      <div>Address of Mother</div>
                      <strong>{data.addressOfMother}</strong>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>Phone Number of Father</div>
                      <strong>{data.phoneNumberOfFather}</strong>{" "}
                    </td>
                    <td>
                      <div>Phone Number of Mother</div>
                      <strong>{data.phoneNumberOfMother}</strong>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}  style={{backgroundColor: colors.lightgray, borderRadius:5}}><h5 >Guardian Information</h5></td>
                  </tr>
                  <tr>
                    <td>
                      <div>Name of Guardian</div>
                      <strong>{data.nameOfGuardian}</strong>{" "}
                    </td>
                    <td>
                      <div>Occupation of Guardian</div>
                      <strong>{data.occupationOfGuardian}</strong>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>Phone Number of Guardian</div>
                      <strong>{data.phoneNumberOfGuardian}</strong>{" "}
                    </td>
                    <td>
                      <div>Residential Address of Guardian</div>
                      <strong>{data.residentialAddressOfGuardian}</strong>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>Postal Address Of Guardian</div>
                      <strong>{data.postalAddressOfGuardian}</strong>{" "}
                    </td>
                    <td>
                      <div>Guardian</div>
                      <strong>{data?.guardian}</strong>{" "}
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>

            <div className="alert alert-warning">By clicking on <strong>CONFIRM</strong>, you have accepted that the data provided is correct. You will <strong>NOT</strong> be able to update the details later after confirmation</div>
            <div className="d-flex justify-content-end">
              <Button color="warning" variant="contained" style={{ marginRight: 20 }} onClick={props.onBackClick}>Edit</Button>
              <Button color="primary" variant="contained" onClick={onConfirmClicked}>Confirm</Button>
            </div>
          </div>


        </div>

      </div>

      <AlertDialogAction open={dialogProps.open} children={dialogProps.message} title={dialogProps.title} disabled={dialogProps.disabled} handleClose={handleDialogClose} onDialogSubmit={handleOnDialogSubmit} />
    </div>
  );
}

export default AdmissionFormPreview;
