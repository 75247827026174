import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function AlertDialog(dialog ={ open:false, title:'', children:'', color:'error', close:'Close', handleClose:function(){} }) {
  const [openDialog, setOpenDialog] = useState(dialog.open);

  useEffect(()=>{
    setOpenDialog(dialog.open)

  }, [dialog])



  return (
      <Dialog
        open={openDialog}
        onClose={dialog.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialog.title}
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          {dialog.open && dialog.children}
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={dialog.handleClose} variant='contained' color={dialog.color}>{dialog.close}</Button>
        </DialogActions>
      </Dialog>
  );
}

export default AlertDialog