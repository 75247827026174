import React, { useEffect, useState } from "react";
import { getPublishedSchools } from "../../api/school";
import logger from "../../util/logger";
import Title from "../../components/Title";
import styles from "../../config/styles";
import colors from "../../config/colors";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@mui/material";

function SupportedSchools() {
  const [publishedSchools, setPublishedSchools] = useState([]);

  const getSchools = async () => {
    const response = await getPublishedSchools();
    logger("supported", response.data);
    if (response.ok) {
      setPublishedSchools(response.data);
    }
  };

  useEffect(() => {
    getSchools();
  }, []);

  return (
    <>
      <Title name="Supported School" />

      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
        style={{ ...styles.paddingTopHeader, ...styles.height, backgroundColor: colors.lightgray }}
      >
        <div className="container" style={{ padding: 20, height: "100%" }}>
          <h4 className="text-center">Supported Schools</h4>
          <div className="text-center">Take a look at our supported schools</div>
          <div style={{ marginTop: 20 }} className="row">
            {publishedSchools.map((value, key) => {
              return (
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 mb-2" key={key}>
                  <NavLink style={{textDecoration:'none'}} to={`https://${value?.nickName}.shsadmissions.com`}>
                    <div className="card" style={{ height: 100 }}>
                      <div className="card-body d-flex flex-row align-items-center">
                        {value.logoUrl && (
                          <img src={value?.logoUrl} style={{ width: 70, height: 70 }} alt="School crest" className="img-responsive" />
                        )}

                        <h5 className="card-title" style={{ marginLeft: 5 }}>
                          {value.name}
                        </h5>
                      </div>
                    </div>
                  </NavLink>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default SupportedSchools;
