import { Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import countries from '../../data/countries'
import Title from '../../components/Title'
import * as voucher from '../../api/voucher'
import { retrieveUser, storeUser } from '../../repository/localstore'
import { useNavigate } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import InputTextField from '../../components/mat/InputTextField'
import InputSelectField from '../../components/mat/InputSelectField'
import logger from '../../util/logger'
import { useRef } from 'react'

let phoneRegEx = /(^0(24|54|55|59|20|50|30|26|56|27|57|23|28))([0-9]){7}/;
const phoneErrorMsg = "Provide a valid phone number from Ghana";

const validationSchema = Yup.object().shape({
    region: Yup.string()
        .required()
        .label("Region"),
    religiousDenomination: Yup.string()
        .required()
        .label("Religious Denomination"),
    physicalChallenge: Yup.string()
        .required()
        .label("Physical Challenge"),
    dateOfBirth: Yup.date().required().label('Date of Birth'),
    placeOfBirth: Yup.string()
        .required()
        .label("Place of Birth"),
    hometown: Yup.string()
        .required()
        .label("Hometown"),
    nationality: Yup.string()
        .required()
        .label("Nationality"),
    address: Yup.string()
        .required()
        .label("Address"),
    phoneNumber: Yup.string()
        .required().max(10).label('Phone Number'),
    nameOfLastSchoolAttended: Yup.string()
        .required()
        .label("Name of Last School Attended"),
    addressOfLastSchoolAttended: Yup.string()
        .required()
        .label("Address of Last School Attended"),
    nameOfFather: Yup.string()
        .required()
        .label("Name of Father"),
    occupationOfFather: Yup.string()
        .required()
        .label("Occupation of Father"),
    addressOfFather: Yup.string()
        .required()
        .label("Address of Father"),
    phoneNumberOfFather: Yup.string().required().max(10)
        .label("Phone Number of Father"),
    nameOfMother: Yup.string()
        .required()
        .label("Name of Mother"),
    occupationOfMother: Yup.string()
        .required()
        .label("Occupation of Mother"),
    addressOfMother: Yup.string()
        .required()
        .label("Address of Mother"),
    phoneNumberOfMother: Yup.string().required().max(10)
        .label("Phone Number of Mother"),
    nameOfGuardian: Yup.string()
        .required()
        .label("Name of Guardian"),
    occupationOfGuardian: Yup.string()
        .required()
        .label("Occupation of Guardian"),
    phoneNumberOfGuardian: Yup.string().required().max(10)
        .label("Phone Number of Guardian"),
    postalAddressOfGuardian: Yup.string()
        .required()
        .label("Postal Address of Guardian"),
    residentialAddressOfGuardian: Yup.string()
        .required()
        .label("Residential Address of Guardian"),
    enrolmentCode: Yup.string()
        .required()
        .label("Enrolment Code"),
    guardian: Yup.string()
        .required()
        .label("Guardian")
})


function AdmissionFormDashboard(props) {
    let { data, student } = props
    const [admissionData, setAdmissionData] = useState(data)
    const isLoaded = useRef(false);

    const navigate = useNavigate()

    const user = JSON.parse(retrieveUser('user'))

    //this method verifies user's voucher code and indexNumber when this page is opened
    const verifyUser = async () => {
        logger('user', user)
        if (user === null) {
            navigate('/');
            return
        }

        const response = await voucher.verifyVoucher({ jhsIndexNumber: user.jhsIndexNumber, voucherNumber: user.voucherNumber })
        if (!response.ok) {
            navigate('/')
        }
    }


    const handleOnClose = () => {
        navigate('/')
    }


    const onSubmitClick = (handleSubmit, setFieldValue, values, setValues, errors) => {
        logger('errors', errors)
        setValues(admissionData)

        handleSubmit()
    }

    const validate = (values) => {
        logger('guardian', values.guardian)
        if (values.guardian === 'Father') {

            setAdmissionData({
                ...values,
                nameOfGuardian: values.nameOfFather,
                occupationOfGuardian: values.occupationOfFather,
                phoneNumberOfGuardian: values.phoneNumberOfFather,
                postalAddressOfGuardian: values.addressOfFather,
                residentialAddressOfGuardian: values.addressOfFather
            })

        }
        else if (values.guardian === 'Mother') {

            setAdmissionData({
                ...values,
                nameOfGuardian: values.nameOfMother,
                occupationOfGuardian: values.occupationOfMother,
                phoneNumberOfGuardian: values.phoneNumberOfMother,
                postalAddressOfGuardian: values.addressOfMother,
                residentialAddressOfGuardian: values.addressOfMother
            })

        }else{
            setAdmissionData({
                ...values
            })
        }

        logger('admission data', admissionData)
        logger('validate', values)

    }

    useEffect(() => {
        if(!isLoaded.current){
            setAdmissionData(data)
            verifyUser();
            isLoaded.current = true;
        }



    }, [])

    return (

        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
            <Title name='Admission Form' />

            <Formik onSubmit={()=>props.onSubmit(admissionData)} initialValues={admissionData} validate={validate} enableReinitialize={true} validationSchema={validationSchema} >

                {({ handleSubmit, setFieldValue, values, setValues, errors }) => (
                    <>
                        {/* col-xs-12 col-sm-12 col-md-11 col-lg-8 */}

                        <div className='card col-xs-12 col-sm-12 col-md-8 col-lg-6'>
                            <div className='card-body'>
                                <table className='table table-responsive'>
                                    <tbody>
                                        <tr>
                                            <td>
                                                Name: <strong> {student?.name}</strong>
                                            </td>

                                            <td>
                                                Program Offered: <strong> {student?.programOffered}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Index Number: <strong>{data.jhsIndexNumber}</strong>
                                            </td>
                                            <td>
                                                Track: <strong>{student?.track}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div>
                                    <Box
                                        component='form'
                                        sx={{
                                            '& .MuiTextField-root': { marginBottom: 2, width: '100%', alignItems: 'stretch' },
                                        }}

                                        noValidate
                                        autoComplete="of"
                                        autoSave='on'>
                                        <legend >Student Biodata</legend>
                                        <div className='row'>
                                            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                                <InputTextField label='Enrolment Code' name='enrolmentCode' defaultValue={admissionData.enrolmentCode} />
                                                <InputTextField label='Place of Birth' name='placeOfBirth' defaultValue={admissionData.placeOfBirth} />
                                                <InputTextField label='Hometown' name='hometown' defaultValue={admissionData.hometown} />
                                                <InputSelectField label='Nationality' name='nationality' options={countries} defaultValue={admissionData.nationality} />
                                                <InputSelectField label='Region' name='region'
                                                    options={
                                                        [
                                                            "Ashanti",
                                                            "Bono",
                                                            "Bono East",
                                                            "Ahafo",
                                                            "Central",
                                                            "Eastern",
                                                            "Greater Accra",
                                                            "Northern",
                                                            "Savannah",
                                                            "North East",
                                                            "Upper East",
                                                            "Upper West",
                                                            "Volta",
                                                            "Oti",
                                                            "Western",
                                                            "Western North"
                                                        ]
                                                    } defaultValue={admissionData.region} />
                                                <InputTextField label='Religious Denomination' name='religiousDenomination' defaultValue={admissionData.religiousDenomination} />

                                            </div>
                                            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                                <InputTextField label='Date of Birth' name='dateOfBirth' type='date' defaultValue={admissionData.dateOfBirth} InputLabelProps={{ shrink: true }} />
                                                <InputTextField label='Address' name='address' defaultValue={admissionData.address} />
                                                <InputTextField label='Phone Number' name='phoneNumber' defaultValue={admissionData.phoneNumber} inputProps={{ maxLength: 10 }} />
                                                <InputSelectField label='Physical Challenge' name='physicalChallenge' defaultValue={admissionData.physicalChallenge}
                                                    options={
                                                        [
                                                            'No Challenge',
                                                            'Partially Sighted',
                                                            'Non Sighted'
                                                        ]
                                                    } />
                                                <InputTextField label='Name of Last School Attended' name='nameOfLastSchoolAttended' defaultValue={admissionData.nameOfLastSchoolAttended} />
                                                <InputTextField label='Address of Last School Attended' name='addressOfLastSchoolAttended' defaultValue={admissionData.addressOfLastSchoolAttended} />

                                            </div>
                                        </div>



                                        {/* PARENT INFORMATION */}
                                        <legend >Parent Information</legend>
                                        <div className='row'>
                                            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                                <InputTextField label='Name of Father' name='nameOfFather' defaultValue={admissionData.nameOfFather} />
                                                <InputTextField label='Occupation of Father' name='occupationOfFather' defaultValue={admissionData.occupationOfFather} />
                                                <InputTextField label='Address of Father' name='addressOfFather' defaultValue={admissionData.addressOfFather} />
                                                <InputTextField label='Phone Number of father' name='phoneNumberOfFather' inputProps={{ maxLength: 10 }} defaultValue={admissionData.phoneNumberOfFather} />

                                            </div>
                                            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                                <InputTextField label='Name of Mother' name='nameOfMother' defaultValue={admissionData.nameOfMother} />
                                                <InputTextField label='Occupation of Mother' name='occupationOfMother' defaultValue={admissionData.occupationOfMother} />
                                                <InputTextField label='Address of Mother' name='addressOfMother' defaultValue={admissionData.addressOfMother} />
                                                <InputTextField label='Phone Number of Mother' name='phoneNumberOfMother' inputProps={{ maxLength: 10 }} defaultValue={admissionData.phoneNumberOfMother} />

                                            </div>
                                        </div>



                                        {/* Guardian Information */}
                                        <legend>Guardian Information</legend>

                                        <div className='row'>

                                            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                                <InputSelectField label='Guardian' name='guardian' options={
                                                    [
                                                        "Father",
                                                        "Mother",
                                                        "Other"
                                                    ]
                                                } defaultValue={admissionData.guardian} />

                                                {
                                                    //if the guardian selected is other than Mother/Father, display fields to fill
                                                    values.guardian === 'Other' && (<>
                                                        <InputTextField label='Name of Guardian' name='nameOfGuardian' defaultValue={admissionData.nameOfGuardian} />
                                                        <InputTextField label='Occupation of Guardian' name='occupationOfGuardian' defaultValue={admissionData.occupationOfGuardian} />
                                                    </>)
                                                }

                                            </div>
                                            {
                                                //if the guardian selected is other than Mother/Father, display fields to fill
                                                values.guardian === 'Other' && (<>
                                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                                        <InputTextField label='Phone Number of Guardian' name='phoneNumberOfGuardian' inputProps={{ maxLength: 10 }} defaultValue={admissionData.phoneNumberOfGuardian} />
                                                        <InputTextField label='Residential Address of Guardian' name='residentialAddressOfGuardian' defaultValue={admissionData.residentialAddressOfGuardian} />
                                                        <InputTextField label='Postal Address of Guardian' name='postalAddressOfGuardian' defaultValue={admissionData.postalAddressOfGuardian} />

                                                    </div>
                                                </>)
                                            }


                                        </div>

                                        <div className='row' style={{ marginTop: 10, marginBottom: 20 }}>
                                            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-end'>
                                                {/* <button type='button' className='btn btn-warning' style={{ marginRight: 20 }} onClick={handleOnClose}>Close </button> */}
                                                {/* <button type='submit' className='btn btn-primary' onClick={() => onSubmitClick(handleSubmit, setFieldValue, values, setValues, errors)}>Submit</button> */}
                                                {/* <Button type='button' color='warning' variant="contained" style={{ marginRight: 20 }} onClick={handleOnClose}>Close</Button> */}
                                                <Button type='button' variant="contained" onClick={() => onSubmitClick(handleSubmit, setFieldValue, values, setValues, errors)}>Next</Button>

                                            </div>
                                        </div>
                                    </Box>
                                </div>
                            </div>

                        </div>

                    </>
                )

                }

            </Formik>

        </div>

    )
}

export default AdmissionFormDashboard