import React, {} from 'react'
import { Route, Routes } from 'react-router-dom'
import routes from '../config/routes'
import AboutUs from '../screens/navbar/AboutUs'
import AdmissionForm from '../screens/admission/AdmissionForm'
import AdmissionFormPreview from '../screens/admission/AdmissionFormPreview'
import AdmissionStatus from '../screens/admistatus/AdmissionStatus'
import AdmissionStatusDashboard from '../screens/admistatus/AdmissionStatusDashboard'
import BuyVoucher from '../screens/buyvoucher/BuyVoucher'
import ContactUs from '../screens/navbar/ContactUs'
import SupportedSchools from '../screens/navbar/SupportedSchools'
import WelcomeScreen from '../screens/WelcomeScreen'
import AdmissionFormMain from '../screens/admission/AdmissionFormMain'
import ConfirmVoucherPurchase from '../screens/buyvoucher/ConfirmVoucherPurchase'
import NotFound from '../screens/NotFound'
import AppMain from '../AppMain'
import AdmissionLetter from '../components/download/AdmissionLetter'
import StudentData from '../components/download/StudentData'
import RetrieveVoucher from '../screens/buyvoucher/RetrieveVoucher'
import PrivacyPolicy from '../screens/navbar/PrivacyPolicy'

function HomePageRouter() {
  
  return (
    <Routes>

      <Route path={routes.ROUTE_BUY_VOUCHER} element={<BuyVoucher />}  />
      <Route path={`${routes.ROUTE_CONFIRM_VOUCHER}`} element={<ConfirmVoucherPurchase /> }/>
      <Route path={routes.ROUTE_RETRIEVE_VOUCHER} element={<RetrieveVoucher />}  />

      <Route path={routes.ROUTE_ADMISSION} element={<AdmissionForm />} />
      <Route path={routes.ROUTE_ADMISSION_FORM} element={<AdmissionFormMain />} />
      <Route path={routes.ROUTE_ADMISSION_STATUS} element={<AdmissionStatus />}  >
      </Route>
      <Route path={routes.ROUTE_ADMISSION_PREVIEW}  element={<AdmissionFormPreview />} />
      <Route path={`${routes.ROUTE_ADMISSION_STATUS}/:indexnumber`} element={<AdmissionStatusDashboard />} />
      <Route path={routes.ROUTE_ADMISSION_LETTER} element={<AdmissionLetter />} />
      <Route path={routes.ROUTE_ADMISSION_BIODATA} element={<StudentData />}/>

      <Route path={routes.ROUTE_SUPPORTED_SCHOOL} element={<SupportedSchools />} />
      <Route path={routes.ROUTE_CONTACT_US} element={<ContactUs />} />
      <Route path={routes.ROUTE_ABOUT_US} element={<AboutUs />} />
      <Route path={routes.ROUTE_PRIVACY_POLICY} element={<PrivacyPolicy />} />



      <Route path='/' element={<WelcomeScreen />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

export default HomePageRouter
