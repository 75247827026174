import React from 'react'
import Title from '../../components/Title'
import colors from '../../config/colors'
import styles from '../../config/styles'

function PrivacyPolicy() {
    return (
        <div>
            <Title name='Contact Us' />


            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ ...styles.paddingTopHeader, ...styles.height, backgroundColor: colors.lightgray }}>

                <div className='container' style={{ padding: 20, height: '100%' }}>
                    {/* <h1 className="text-center">Contact Us</h1> */}
                    <div className='text-center d-flex flex-column justify-content-center align-items-center' style={{ padding: 10 }}>
                        <h4>Privacy Policy</h4>

                        <div className="card card-body col-xs-12 col-sm-12 col-md-4 col-lg-4 " style={{ backgroundColor: colors.white, marginTop: 50 }}>
                            <div className='text-center'>
                            </div>
                            <div >
                               
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    )
}

export default PrivacyPolicy