import React from 'react'
import iconLoading from '../assets/loading-state.gif'
function LoadingComponent() {
  return (
    
    <div style={{padding:'15%'}} className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center flex-column">
        <img src={iconLoading} width={100} height={100} alt='Loading' />
    </div>
    
  )
}

export default LoadingComponent