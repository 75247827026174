import React from 'react'
import Title from '../../components/Title'
import colors from '../../config/colors'
import styles from '../../config/styles'

function AboutUs() {
  return (
    <div>
      <Title name='About Us'/>

      
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ ...styles.paddingTopHeader, ...styles.height, backgroundColor: colors.lightgray }}>

        <div className='container' style={{ padding: 20, height: '100%' }}>
              <h1 className="text-center">About Us</h1>
              <div style={{marginTop:30}}>

              </div>
            </div>


      </div>

      

    </div>
  )
}

export default AboutUs