import React, { useCallback, useEffect, useState } from 'react'
import { retrieveUser } from '../../repository/localstore';
import logger from '../../util/logger';

/**
 * 
 * @returns Image element of a random picture from a list of pictures of a school
 */
function SchoolPicture() {
    const [schoolImg, setSchoolImg] = useState("");
    const school = JSON.parse(retrieveUser('school'))
    const isSubdomain = JSON.parse(retrieveUser("isSubdomain"))

    const getRandomPic = (max) => {
        return Math.floor(Math.random() * max)
    }

    const setSchoolImage = useCallback(()=> {
      if(!school){
        return;
      }
        const rand = getRandomPic(school?.images?.length || 0)

        setSchoolImg(school?.images[rand])

        logger('random', schoolImg)
    }, [school, schoolImg])

    useEffect(() => {
      if(isSubdomain){
        setSchoolImage();
      }
    }, [isSubdomain, setSchoolImage])

  return (
    <>
       {(schoolImg && isSubdomain) ?   <img src={schoolImg} alt='Random School ' style={{width:'100%', borderRadius:5}} className="img-responsive"/> :"" }
       {/* <h3 className='text-center'>{school.school.name}</h3> */}
    </>
  )
}

export default SchoolPicture