import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import iconVoucher from "../../assets/voucher.png";
import { Box, Button } from "@mui/material";
import AppSnackBar from "../../components/AppSnackBar";
import severity from "../../config/severity";
import { Link, useNavigate } from "react-router-dom";
import Title from "../../components/Title";
import * as voucher from "../../api/voucher";
import { retrieve, retrieveUser, store } from "../../repository/localstore";
import InputTextField from "../../components/mat/InputTextField";
import styles from "../../config/styles";
import InputSelectField from "../../components/mat/InputSelectField";
import { getPublishedSchools } from "../../api/school";
import SchoolPicture from "../../components/school/SchoolPicture";
import logger from "../../util/logger";
import colors from "../../config/colors";
import SchoolCarousel from "../../components/school/SchoolCarousel";
import routes from "../../config/routes";
import AlertDialogAction from "../../components/AlertDialogAction";
import { buyVoucher } from "@Crafterhive/cssps";
import { useCallback } from "react";

let phoneRegEx = /(^0(24|54|55|59|20|50|30|26|56|27|57|23|28))([0-9]){7}/;
const phoneErrorMsg = "Provide a valid phone number from Ghana";
let schoolData = {}; //holds the data of the school from session storage

const validationSchema = Yup.object().shape({
  schoolId: Yup.string().required().label("School Placed"),
  jhsIndexNumber: Yup.string().required().min(12).max(12).label("JHS Index Number"),
  phoneNumber: Yup.string().required().min(10).max(10).label("Phone Number"),
});

function BuyVoucher() {
  const navigate = useNavigate();
  const [buttonState, setButtonState] = useState({ disabled: false, text: "Buy Voucher" });
  const [isSchool, setSchool] = useState(false);
  const [schools, setSchools] = useState([]);
  const [schoolLogo, setSchoolLogo] = useState("");
  const isSubdomain = JSON.parse(retrieveUser("isSubdomain"));
  //set the default props for the snackbar
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const [buyVoucherVal, setBuyVoucherVal] = useState({
    jhsIndexNumber: "",
    phoneNumber: "",
    schoolId: "",
    schoolCode: "",
  });
  const [dialogProps, setDialogProps] = useState({
    open: false,
    title: "",
    message: React.Component,
    disabled: false,
  });

  //submit data to server method
  const submitToServer = async () => {
    try {
      const callbackUrl = retrieve("callbackUrl");
      let school = schools.find((value, index) => value.id === buyVoucherVal.schoolId);

      if(!school){
        //if a school is not found, retrieve the school again.
        publishedSchools();
        return;
      }

      console.log("schools", school);

      const val = {
        schoolId: buyVoucherVal.schoolId,
        jhsIndexNumber: buyVoucherVal.jhsIndexNumber,
        phoneNumber: `233${buyVoucherVal.phoneNumber.substr(1, 9)}`,
        callbackUrl: callbackUrl,
        schoolCode: school.schoolCode,
      };
      setButtonState({ disabled: true, text: "Please wait..." });
      setDialogProps({ open: false, title: "Confirm Number", disabled: false });

      console.log("buy voucher vals", val);

      // const response = await voucher.buyVoucher(val);
      const response = await buyVoucher(val);

      console.log("response", response);

      //if response is correct
      setButtonState({ disabled: false, text: "Buy Voucher" });

      //save jhsIndexNumber and schoolID in localStorage
      const respData = {
        jhsIndexNumber: buyVoucherVal.jhsIndexNumber,
        reference: response.reference,
      };
      store("user", JSON.stringify(respData));
      window.location = response.checkoutUrl;
    } catch (error) {
      console.log(error);
      setButtonState({ disabled: false, text: "Buy Voucher" });
      setSnackBarProps({ open: true, severity: severity.error, message: error?.response?.data?.message || error?.message || "Unexpected Error" });
    }
  };

  //callback from dialog close
  const handleDialogClose = () => {
    setDialogProps({ open: false, disabled: false });
  };

  //callback from the dialog data
  const handleOnDialogSubmit = (values) => {
    submitToServer(values);
  };

  //auto close snackbar
  const handleSnackClose = () => {
    setSnackBarProps({ open: false, severity: "error", message: "" });
  };

  //check if it is a custom school
  const checkSchool = useCallback(() => {
    schoolData = JSON.parse(retrieveUser("school"));
    if (schoolData && isSubdomain) {
      setSchool(true);
      setSchoolLogo(schoolData.school.logoUrl);
    } else {
      setSchool(false);
    }
  },[isSubdomain]);

  //get all published schools
  const publishedSchools = async () => {
    const response = await getPublishedSchools();
    console.log("published", response.data);
    if (response.ok) {
      setSchools(response.data);
    }
  };

  const handleDataSubmit = (handleSubmit, values, setValues) => {
    setValues(buyVoucherVal);
    handleSubmit();
  };

  const onSubmit = async (values) => {
    setDialogProps({
      open: true,
      title: "Confirm Number",
      message: (
        <div>
          The voucher code will be sent to this number: <strong>{values.phoneNumber}</strong>. Submit?
        </div>
      ),
      disabled: false,
    });
  };

  /**
   * When submit button is clicked, this method runs first,
   * Therefore, update the data in the state variable,
   * Then validate the values inside the handleDataSubmit function
   * @param {*} values
   */
  const validate = (values) => {
    //if school data is not in session, take from the
    logger("school data", schoolData);
    if (isSubdomain && schoolData !== null) {
      setBuyVoucherVal({
        jhsIndexNumber: values.jhsIndexNumber,
        schoolId: schoolData.school.id,
        schooCode: schoolData.school.schoolCode,
        phoneNumber: values.phoneNumber,
      });
    } else {
      setBuyVoucherVal({ ...values });
    }
  };

  useEffect(() => {
    checkSchool();
    publishedSchools();
  }, [checkSchool]);

  return (
    <>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center" style={styles.height}>
        <Title name="Buy Voucher" />

        <Formik
          initialValues={buyVoucherVal}
          validate={validate}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => onSubmit(values)}
        >
          {({ handleSubmit, setValues, values, handleChange, setFieldValue }) => (
            <>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-none d-sm-block" style={{ height: "100%", backgroundColor: colors.lightgray }}>
                <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                    <SchoolPicture />
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6" style={{ margin: "auto" }}>
                  <div className="container">
                    <div style={{ paddingBottom: 25 }}>
                      <div className="text-center">
                        <img src={schoolLogo && isSubdomain ? schoolLogo : iconVoucher} style={styles.formLogo} alt="school logo" />
                      </div>
                      <h4 className="text-center">Buy Voucher</h4>
                      <div className="alert alert-primary">
                        <ul>
                          {!isSubdomain && <li>Select the exact school you're placed from the list below.</li>}
                          <li>
                            Enter your 10 digits Index Number followed by the <strong>year. eg. 100000023</strong>
                          </li>
                          <li>Enter a valid phone number to buy a voucher</li>
                        </ul>
                      </div>
                    </div>

                    <Box
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { marginBottom: 2, width: "100%" },
                      }}
                      noValidate
                      autoComplete="off"
                      autoSave="on"
                    >
                      {!isSubdomain && (
                        <InputSelectField
                          label="Select School Placed"
                          name="schoolId"
                          options={schools}
                          size="large"
                          defaultalue={buyVoucherVal.schoolId}
                        />
                      )}
                      <InputTextField
                        label="JHS Index Number"
                        name="jhsIndexNumber"
                        size="large"
                        defaultalue={buyVoucherVal.jhsIndexNumber}
                        inputProps={{  maxLength: 12 }}
                      />

                      <InputTextField
                        label="Phone Number"
                        name="phoneNumber"
                        placeholder="eg. 0240000000"
                        inputProps={{ maxLength: 10 }}
                        size="large"
                        defaultalue={buyVoucherVal.phoneNumber}
                      />
                      <div style={{ marginTop: 10 }}>
                        <Button
                          type="button"
                          style={styles.formButton}
                          disabled={buttonState.disabled}
                          variant="contained"
                          onClick={() => handleDataSubmit(handleSubmit, values, setValues)}
                        >
                          {buttonState.text}
                        </Button>
                      </div>
                    </Box>

                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      Lost your voucher?{" "}
                      <strong>
                        {" "}
                        <Link to={"/" + routes.ROUTE_RETRIEVE_VOUCHER}>Click Here</Link>{" "}
                      </strong>
                    </div>
                  </div>

                  <AppSnackBar
                    open={snackBarProps.open}
                    severity={snackBarProps.severity}
                    message={snackBarProps.message}
                    handleClose={handleSnackClose}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>

        <AlertDialogAction
          open={dialogProps.open}
          children={dialogProps.message}
          title={dialogProps.title}
          disabled={dialogProps.disabled}
          handleClose={handleDialogClose}
          onDialogSubmit={handleOnDialogSubmit}
        />
      </div>
    </>
  );
}

export default BuyVoucher;
