import React, { useEffect, useState } from "react";
import "./App.css";
import LoadingComponent from "./components/LoadingComponent";
import AppMain from "./AppMain";
import NotFound from "./screens/NotFound";
import { getSchool } from "./api/school";
import { storeUser, store, retrieve } from "./repository/localstore";
import logger from "./util/logger";
import routes from "./config/routes";

function App() {
  const [message, setMessage] = useState("");
  const [isSchool, setSchool] = useState({
    loading: true,
    found: true,
  });

  /**
   * This method checks if a user is accesses the domain from a subdomain/school nickname.
   * eg. sogasco.shsadmissions.com
   * This method handles edge cases such as www.sogasco.shsadmissions.com or sogasco.shsadmissions.com
   */
  const checkSubdomain = async () => {
    let location = "";
    let subD = "";
    let domain = "";

    if (process.env.NODE_ENV === "development") {
      location = "https://shsadmissions.com";
      // location = window.location.origin;
      logger("hostname", location);
      store("callbackUrl", `${window.location.origin}/${routes.ROUTE_CONFIRM_VOUCHER}`); //save the domain and subdomaIN
    } else {
      //get the domain the user is on and append confirm voucher endpoint to it
      //store the full url in a local storage
      //this is used as a callback url when a user buys a voucher and it's been verified
      // const callback = location.substring(0, location.lastIndexOf('.com')+4)
      location = window.location.href;
      store("callbackUrl", `${window.location.origin}/${routes.ROUTE_CONFIRM_VOUCHER}`); //save the domain and subdomain
    }

    //this snippet/else if statement checks if a user is on a subdomain or main domain
    //this is to customize pages based on a school's subdomain
    subD = location.split(".");
    if (location.includes("www") && subD.length === 4) {
      domain = subD[1];
    } else if (!location.includes("www") && subD.length === 3) {
      domain = subD[0].substring(subD[0].lastIndexOf("/") + 1, subD[0].length);
    } else {
      //its on the main domain
      storeUser("isSubdomain", false);
      setSchool({ loading: false, found: true });
      return;
    }

    logger("subdomain", subD);
    logger("domain", domain);

    const response = await getSchool(domain);
    logger("sch domain", response);
    //if school is not found
    if (!response.ok) {
      setSchool({ loading: false, found: false });
      setMessage(response?.data?.message);
      return;
    }
    //if school is found, save in session
    storeUser("isSubdomain", true);
    storeUser("school", JSON.stringify(response?.data));
    setSchool({ loading: false, found: true });
  };

  useEffect(() => {
    checkSubdomain();
  }, []);

  return (
    <>
      {/* If the page starts loading, display Loading component */}
      {isSchool.loading && <LoadingComponent />}
      {/* if schoolLoading is false, and found is true, display App Main component */}
      {!isSchool.loading && isSchool.found && <AppMain />}
      {/* if school is not found, display not found component */}
      {!isSchool.found && <NotFound message={message} />}
    </>
  );
}

export default App;
