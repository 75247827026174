import React, { useState, forwardRef, useEffect } from 'react';
import { Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert';

//to add alert inside the snackbar
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AppSnackBar(snack = { open: false, severity: 'success', message: '', handleClose:function(){} }) {

  //set the initial state of the snackbar to false
  const [openSnack, setOpenSnack] = useState(snack.open)

  // //triggered when the snackbar duration is done
  // const handleClose = (event, reason) => {

  //   if (reason === 'clickaway') {
  //     return;
  //   }

  //   //set the snackbar state to false
  //   setOpenSnack(!snack.open);
  // };

  
  useEffect(() => {
    setOpenSnack(snack.open)

  }, [snack])


  return (
    <Snackbar open={openSnack} autoHideDuration={6000} onClose={snack.handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={snack.handleClose} severity={snack.severity} sx={{ width: '100%' }}>
        {snack.message}
      </Alert>
    </Snackbar>
  )
}

export default AppSnackBar