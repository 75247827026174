import React from 'react'
import HomePageRouter from './routes/HomePageRouter';
import Header from './components/Header';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#20a8d8',
            contrastText: 'white'
        }
    },


});

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        backgroundColor: props.backgroundColor,
        color: theme.color,
    }),
}));

function AppMain() {

    const classes = useStyles()
    return (
        <ThemeProvider theme={theme} >
            <div title='SHS Admissions' className={classes.root}>
                <div style={{ width:'100%', zIndex:1, boxShadow:`0px 0px 2px gray`,}}>
                    <div className='container'><Header /></div>

                </div>

                <HomePageRouter />
            </div>
        </ThemeProvider>
    )
}

export default AppMain