import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import colors from '../../config/colors'
import { retrieveUser } from '../../repository/localstore';
import routes from '../../config/routes';
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

function StudentData() {
    const data = JSON.parse(retrieveUser('ddata'))
    const schh = JSON.parse(retrieveUser('school'))
    const navigate = useNavigate()
    const printCompRef = useRef()

    useEffect(() => {

        handlePrint()
        // printPDF()
        navigate("/" + routes.ROUTE_ADMISSION_STATUS + "/" + data?.jhsIndexNumber, { replace: true })
    }, [])

    const handlePrint = useReactToPrint({
        // 230mm 380mm
        content: () => printCompRef.current,
        pageStyle: `@page {
            size: 280mm 440mm;
            margin: 5mm;
            
                }`


    })

    const printPDF = () => {
        const input = document.getElementById('printToPDF');

        html2canvas(input, { scale: 1.1 }).then(function (canvas) {
            // document.body.appendChild(canvas);
            var imgdata = canvas.toDataURL("image/jpg");
            var doc = new jsPDF({ orientation: 'portrait', unit: 'px', userUnit: 'px', format: 'a4' });
            doc.addImage(imgdata, "JPG", 5, 5);
            doc.save(`personal_record_${data?.jhsIndexNumber}.pdf`)
            window.open(doc.output("bloburl"), "_blank");
        });

    }

    return (
            < div className='card-body' >
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center" style={{ paddingTop: 50, backgroundColor: colors.lightgray }}>


                <div className="card col-xs-12 col-sm-12 col-md-7 col-lg-7">
                    <div id="printToPDF" ref={printCompRef} style={{ width: '100%' }}>
                        <div className='container'>

                            {/* <button className='btn btn-primary' onClick={handlePrint}>Print</button> */}
                            {/* <p className='alert alert-primary'>
                        For best results, please print this on the computer
                    </p> */}

                            <h3 style={{ display: 'flex', justifyContent: 'center' }}>{schh?.school?.name}</h3>

                            <table className="table table-responsive" >
                                <tbody>
                                    <tr>
                                        <td colSpan={2} style={{ backgroundColor: colors.lightgray, borderRadius: 5 }} ><h5>Student Biodata</h5></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Full Name</div>
                                            <strong>{data?.name}</strong>
                                        </td>
                                        <td>
                                            <div>Sex</div>
                                            <strong>{data?.gender}</strong>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td>
                                            <div>Enrolment Code</div>
                                            <strong>{data?.enrolmentCode}</strong>
                                        </td>
                                        <td>
                                            <div>Date of Birth</div>
                                            <strong>{data?.dateOfBirth}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Place of Birth</div>
                                            <strong>{data?.placeOfBirth}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Address</div>
                                            <strong>{data?.address}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Hometown</div>
                                            <strong>{data?.hometown}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Phone Number</div>
                                            <strong>{data?.phoneNumber}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Nationality</div>
                                            <strong>{data?.nationality}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Physical Challenge</div>
                                            <strong>{data?.physicalChallenge}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Region</div>
                                            <strong>{data?.region}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Religious Denomination</div>
                                            <strong>{data?.religiousDenomination}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Name of Last School Attended</div>
                                            <strong>{data?.nameOfLastSchoolAttended}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Address of Last School</div>
                                            <strong>{data?.addressOfLastSchoolAttended}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ backgroundColor: colors.lightgray, borderRadius: 5 }} ><h5 >Parent Information</h5></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Name of Father</div>
                                            <strong>{data?.nameOfFather}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Name of Mother</div>
                                            <strong>{data?.nameOfMother}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Occupation of Father</div>
                                            <strong>{data?.occupationOfFather}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Occupation of Mother</div>
                                            <strong>{data?.occupationOfMother}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Address of Father</div>
                                            <strong>{data?.addressOfFather}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Address of Mother</div>
                                            <strong>{data?.addressOfMother}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Phone Number of Father</div>
                                            <strong>{data?.phoneNumberOfFather}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Phone Number of Mother</div>
                                            <strong>{data?.phoneNumberOfMother}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ backgroundColor: colors.lightgray, borderRadius: 5 }}><h5 >Guardian Information</h5></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Name of Guardian</div>
                                            <strong>{data?.nameOfGuardian}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Occupation of Guardian</div>
                                            <strong>{data?.occupationOfGuardian}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Phone Number of Guardian</div>
                                            <strong>{data?.phoneNumberOfGuardian}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Residential Address of Guardian</div>
                                            <strong>{data?.residentialAddressOfGuardian}</strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Postal Address Of Guardian</div>
                                            <strong>{data?.postalAddressOfGuardian}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Guardian</div>
                                            <strong>{data?.guardian}</strong>{" "}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={2} style={{ backgroundColor: colors.lightgray, borderRadius: 5 }}><h5 >Official Use Only</h5>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Admission Number</div>
                                            <strong>{data?.admissionNumber}</strong>
                                        </td>
                                        <td>
                                            <div>BECE Index Number</div>
                                            <strong>{data?.jhsIndexNumber}</strong>
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>
                                            <div>Track</div>
                                            <strong>{data?.track}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>Program Offered</div>
                                            <strong>{data?.programOffered}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Residential Status</div>
                                            <strong>{data?.residentialStatus}</strong>
                                        </td>
                                        <td>
                                            <div>Class</div>
                                            <strong></strong>{" "}
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>
                                            <div>House Allocated</div>
                                            <strong>{data?.houseAllocated?.houseName}</strong>{" "}
                                        </td>
                                        <td>
                                            <div>House Parent</div>
                                            <strong>{data?.houseAllocated?.houseMaster ? data?.houseAllocated?.houseMaster : data?.houseAllocated?.houseMistress}</strong>{" "}
                                            {/* <strong>{(data?.gender === "Female" && data?.houseAllocated?.houseMistress !== null) ? data?.houseAllocated?.houseMistress : data?.houseAllocated?.houseMaster}</strong>{" "} */}

                                        </td>
                                    </tr>

                                    <tr style={{ paddingBottom: 10 }}>
                                        <td>
                                            <div style={{ marginTop: 20 }}>
                                                Student's Signature:..................................................
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ marginTop: 20 }}>
                                                Date(DD/MM/YYYY):.....................................................
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>


                    </div>

                </div>
            </div>
        </div >
    )
}

export default StudentData