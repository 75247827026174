
/**
 * this method stores data in local storage
 * @param {string} key the key of the value
 * @param {string} value  the string of object to sore
 * */
const store = (key, value) => {
    localStorage.setItem(key, value);
}

/**Store user in session storage */
const storeUser= (key, value)=>{
   sessionStorage.setItem(key, value)
}

//this method retrieves data from local storage
const retrieve = (key) => {
    const data = localStorage.getItem(key)
    if (data !== undefined)
        return data

    return undefined;
}

//this method retrieves data from session storage
const retrieveUser =  (key) => {
    const data = sessionStorage.getItem(key)
    if (data !== undefined)
        return data

    return undefined;
}

const remove = (key) => {
    if (localStorage.getItem(key) !== undefined) {
        localStorage.removeItem(key)
    }
}

const removeUser = (key) => {
    if (sessionStorage.getItem(key) !== undefined) {
        sessionStorage.removeItem(key)
    }
}

const clearAll = () => {
    localStorage.clear()
    sessionStorage.clear()
}

const clearSession = () => {
    sessionStorage.clear();
}

export { store, retrieve, remove, clearAll, storeUser, retrieveUser, removeUser, clearSession }

