export default Object.freeze({
    ROUTE_BUY_VOUCHER: 'code/buy',
    ROUTE_CONFIRM_VOUCHER:'code/verify',
    ROUTE_RETRIEVE_VOUCHER:'code/retrieve',
    ROUTE_ADMISSION_STATUS:'admission/status',
    ROUTE_ADMISSION: 'admission',
    ROUTE_ADMISSION_FORM:'admission/form',
    ROUTE_ADMISSION_PREVIEW: 'admission/confirm',
    ROUTE_ADMISSION_LETTER:'admission/letter',
    ROUTE_ADMISSION_BIODATA:'admission/biodata',
    ROUTE_CONTACT_US: 'contact-us',
    ROUTE_ABOUT_US: 'about-us',
    ROUTE_SUPPORTED_SCHOOL:'supported-schools',
    ROUTE_PRIVACY_POLICY: 'privacy-policy'
})