import { create } from "apisauce";
import { config } from "../util/Constants";

const apiClient = create({
    baseURL: config.url.API_URL
});

//add authentication token when present
apiClient.addAsyncRequestTransform(async (request)=>{
    
    //if authentication token is present, assign it to the x-token header
    request.headers['Content-Type'] = "application/json"; 
});

export default apiClient;