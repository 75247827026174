import { TextField } from '@mui/material'
import { useFormikContext } from 'formik'
import React from 'react'

function InputTextField({ label, name, size='small', ...props }) {
    const { errors, touched, handleBlur, handleChange } = useFormikContext()

    return (
        <div>
            <TextField id="outlined-error-helper-text" label={label} variant='outlined'
                onChange={handleChange}
                onBlur={handleBlur}
                name={name}
                error={(errors[name] && touched[name]) ? true : false}
                {...props}
                helperText={(errors[name] && touched[name]) ? errors[name] : ''}
                size={size}
                {...props}
              
            />

        </div>
    )
}

export default InputTextField