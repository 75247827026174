import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { useFormikContext } from 'formik'
import React from 'react'
import inputStyle from '../inputstyle'

function InputSelectField({ label, name, placeholder = '', options = [], defaultValue, size='small' }) {
    const { errors, touched, handleBlur, handleChange } = useFormikContext()

    return (
        <div 
        >
            <FormControl sx={{marginBottom:2, width:'100%'}}>
            <InputLabel id={name}>{label}</InputLabel>
            <Select style={{width:'100%'}}
               labelId={name}
               name={name}
               label={label}
               onChange={handleChange}
               onBlur={handleBlur}
               size={size}
               defaultValue={defaultValue ? defaultValue: ""}
               >
                {
                    options.map((props, key) => {
                        return (
                            <MenuItem key={key} value={props.id ? props.id : props}>{props.name ? props.name : props}</MenuItem>
                        )
                    })
                }

            </Select>
            {(errors[name] && touched[name]) && <FormHelperText id={name}  style={{marginLeft:10, ...inputStyle.errorText}}>{errors[name]}</FormHelperText>}
            </FormControl>
        </div>
    )
}

export default InputSelectField